* {
    margin: 0;
    padding: 0;
}

.react-daterange-picker__wrapper {
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d2d6da !important
}

.react-daterange-picker__inputGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.react-daterange-picker__inputGroup__year {
    width: 40px !important;
}

.react-daterange-picker__inputGroup__year::placeholder,
.react-daterange-picker__inputGroup__month::placeholder,
.react-daterange-picker__inputGroup__day::placeholder {
    font-size: 16px;
}

.react-daterange-picker__inputGroup__year[type='number'],
.react-daterange-picker__inputGroup__month[type='number'],
.react-daterange-picker__inputGroup__day[type='number'] {
    font-size: 16px;
    color: #495057;
}

.react-daterange-picker__inputGroup__year:focus,
.react-daterange-picker__inputGroup__month:focus,
.react-daterange-picker__inputGroup__day:focus {
    background-color: white !important;
}

.react-daterange-picker__inputGroup__input:invalid {
    background-color: transparent !important;
}

.react-daterange-picker__inputGroup {
    min-width: 0 !important;
    margin: 0px 0px 0px 5px;
}

.react-daterange-picker__calendar-button .react-daterange-picker__button {
    border: none !important;
    background-color: transparent !important;
}

/* Custom ScrollBar css */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(111, 110, 110, 0.3);
    background-color: #bbbbbb;
}

.openingBalance {
    border: 1px solid lightgray;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    width: 100%;
    height: 44px;
    border-radius: 0.375rem;
    padding-left: 6px;
    outline: none;
}

.openingBalance:focus {
    border: 2px solid #1565c0 !important;
    border-left: none;
}

::placeholder {
    color: #7b809a;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0.9;
    letter-spacing: 0.00938em;
}

.filterModal {
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background-color: white;
}

/* Add & Edit Modal css */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: auto;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
}

.filedGrid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 2% !important;
}

.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f2f5;
    overflow: hidden;
}

.loader p {
    background: -webkit-linear-gradient(195deg, #49a3f1, #1A73E8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.30);
    font-weight: 900;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.5, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}

@media screen and (min-width: 320px) {
    .navBar {
        display: flex;
        padding: 4px 0px;
    }

    .react-daterange-picker__wrapper {
        display: flex;
        width: 260px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #d2d6da !important
    }

    .modal {
        width: 300px;
        overflow-y: auto;
        max-height: 96vh;
    }

    .filedGrid {
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .materialFiled {
        display: grid !important;
        grid-template-columns: auto auto !important;
        row-gap: 6% !important;
        column-gap: 2% !important;
        margin-top: 17px !important;
        border-radius: 4px;
        box-shadow: 10px 10px 5px 12px lightblue;
    }

    .materialTypeField {
        max-width: 100% !important;
    }

    .khatabookHead {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .khataBookDateSection,
    .outstandingSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0px 5px 0px;
    }

    .khataBook {
        margin: 0px 0px;
        display: flex;
        align-items: start;
        padding: 0px 16px
    }

    .printBtn,
    .listPrintBtn {
        margin-top: 10px;
    }

    .btn {
        width: 10px !important;
        height: 10px !important;
        padding: 0px !important;
    }

    .khataBookGridTable {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .khatBookTableSection {
        width: 100%;
        margin-top: 10px;
    }

    .loader p {
        width: 50%;
        text-align: center;
    }

    .footer {
        position: fixed;
        bottom: 4px;
    }
}

@media screen and (max-width:650px) and (min-width: 400px) {

    .printBtn,
    .listPrintBtn {
        margin-top: 0px;
        margin-left: 10px;
    }

    .react-daterange-picker__wrapper {
        display: flex;
        width: 260px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #d2d6da !important
    }

    .btn {
        width: 10px !important;
        height: 10px !important;
        padding: 0px !important;
    }

    .khataBookDateSection,
    .outstandingSection {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        margin: 10px 0px 5px 0px;
    }
}

@media screen and (max-width:650px) and (min-width: 321px) {
    .modal {
        width: 80%;
        overflow-y: auto;
        max-height: 95vh;
    }

    .khatabookHead {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer {
        text-align: center;
        width: 100%;
        position: fixed;
        bottom: 2px;
    }
}

@media screen and (min-width: 500px) {
    .materialFiled {
        display: flex !important;
        justify-content: space-between !important;
        align-items: start !important;
        margin-top: 3px !important;
    }

    .outstandingSection {
        display: flex;
        flex-direction: row;
        align-items: center !important;
        justify-content: end;
        margin: 10px 0px;
    }

    .listPrintBtn {
        margin-top: 0px;
        margin-left: 20px;
    }

    .navBar {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px;
    }

    .loader p {
        width: 90%;
    }
}

@media screen and (min-width:651px) {
    .modal {
        width: 600px;
        overflow-y: auto;
        max-height: 90vh;
    }

    .filedGrid {
        display: grid;
        grid-template-columns: auto auto;
        height: auto;
    }

    .materialFiled {
        margin-top: 10px !important;
    }

    .khataBook {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        padding: 0px 16px
    }

    .khatabookHead {
        width: 100% !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
        margin: 0px 0px 0px 15px;
    }

    .khataBookDateSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px
    }

    .printBtn {
        margin-top: 0px;
        margin-left: 20px
    }

    .khataBookGridTable {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .khatBookTableSection {
        width: 50%;
        margin: 0px 7px;
    }

}